import { Link } from "gatsby"
import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>
      Join the mailing list for&nbsp;
      <a
        href="https://indicativequant.com/list"
        style={{
          textDecoration: `none`,
        }}
      >
        daily updates
      </a>
      &nbsp;from the quant-o-verse.
    </p>
    <p>
      Read the latest from the&nbsp;
      <Link
        to="/blog"
        style={{
          textDecoration: `none`,
        }}
      >
        blog
      </Link>
      .
    </p>
  </Layout>
)

export default IndexPage
